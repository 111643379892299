import { createBreakpoints } from './breakpoints';
import { createColors, ThemeColors, ThemeColorsInput } from './createColors';
import { createComponents } from './createComponents';
import { createShadows } from './createShadows';
import { createShape, ThemeShapeInput } from './createShape';
import { createSpacing, ThemeSpacingOptions } from './createSpacing';
import { createTransitions } from './createTransitions';
import { createTypography, ThemeTypographyInput } from './createTypography';
import { createV1Theme } from './createV1Theme';
import { createVisualizationColors } from './createVisualizationColors';
import { GrafanaTheme2, WideSkyCustomTheme } from './types';
import { zIndex } from './zIndex';

const WS_NOT_SET = 'NOT_SET';

/** @internal */
export interface NewThemeOptions {
  name?: string;
  colors?: ThemeColorsInput;
  spacing?: ThemeSpacingOptions;
  shape?: ThemeShapeInput;
  typography?: ThemeTypographyInput;
}

/** @internal */
export function createTheme(options: NewThemeOptions = {}, wsTheme?: WideSkyCustomTheme): GrafanaTheme2 {
  const {
    colors: colorsInput = {},
    spacing: spacingInput = {},
    shape: shapeInput = {},
    typography: typographyInput = {},
  } = options;

  const colors = createColors(colorsInput);
  let wsFont;
  if (usingWideSkyTheme(options) && wsTheme) {
    wsFont = {
      header: wsTheme.fontHeader,
      body: wsTheme.fontBody,
    };
    applyWideSkyTheme(colors, wsTheme);
  }

  const breakpoints = createBreakpoints();
  const spacing = createSpacing(spacingInput);
  const shape = createShape(shapeInput);
  const typography = createTypography(colors, typographyInput, wsFont);
  const shadows = createShadows(colors);
  const transitions = createTransitions();
  const components = createComponents(colors, shadows);
  const visualization = createVisualizationColors(colors);

  const theme = {
    name: colors.mode[0].toUpperCase() + colors.mode.substring(1),
    isDark: colors.mode === 'dark',
    isLight: colors.mode === 'light',
    isWideSky: colors.mode === 'WideSky',
    colors,
    breakpoints,
    spacing,
    shape,
    components,
    typography,
    shadows,
    transitions,
    visualization,
    zIndex: {
      ...zIndex,
    },
    flags: {},
  };

  if (usingWideSkyTheme(options) && wsTheme) {
    const { basedOff } = wsTheme;
    if (basedOff === 'dark') {
      theme.isDark = true;
      theme.isLight = false;
    } else {
      theme.isDark = false;
      theme.isLight = true;
    }
  }

  return {
    ...theme,
    v1: createV1Theme(theme),
  };
}

/**
 * Apply a gradient CSS. If all is not set, comprise gradient from the template. Otherwise use the all string.
 * @param template Template to add colourA and colourB to.
 * @param colourA Colour for $0 in template.
 * @param colourB Colour for $1 in template.
 * @param all A complete gradient CSS.
 */
function getGradient(template: string, colourA: string, colourB: string, all: string) {
  if (all === WS_NOT_SET) {
    // just customise the colours
    template = template.replace('$0', colourA);
    template = template.replace('$1', colourB);

    return template;
  } else {
    // allow full user customisation
    return all;
  }
}

function fromBaseOrValue(base: string, value: string, opacity: number) {
  return base === WS_NOT_SET ? value : `rgba(${base}, ${opacity})`;
}

function asValueOrDefault(value: string, defaultVal: string) {
  return value === WS_NOT_SET ? defaultVal : value;
}

function usingWideSkyTheme(options: NewThemeOptions) {
  return options && options.colors && options.colors.mode && options.colors.mode === 'WideSky';
}

function applyWideSkyTheme(theme: ThemeColors, config: WideSkyCustomTheme) {
  const { baseColour, borderColour } = config;

  theme.background.canvas = config.backgroundCanvas;
  theme.background.primary = config.backgroundPrimary;
  theme.background.secondary = config.backgroundSecondary;

  theme.gradients.brandHorizontal = getGradient(
    'linear-gradient(90deg, $0 0%, $1 100%);',
    config.brandGradientHorizontalColourA,
    config.brandGradientHorizontalColourB,
    config.brandGradientHorizontalAll
  );
  theme.gradients.brandVertical = getGradient(
    'linear-gradient(0.01deg, $0 -31.2%, $1 113.07%);',
    config.brandGradientVerticalColourA,
    config.brandGradientVerticalColourB,
    config.brandGradientVerticalAll
  );

  theme.primary.main = config.primaryMain;
  theme.primary.shade = config.primaryHover;
  theme.primary.text = config.primaryText;
  theme.primary.contrastText = config.primaryText;

  theme.secondary.main = fromBaseOrValue(baseColour, config.secondaryMain, 0.16);
  theme.secondary.shade = fromBaseOrValue(baseColour, config.secondaryHover, 0.2);
  theme.secondary.text = fromBaseOrValue(baseColour, config.secondaryText, 1);
  theme.secondary.contrastText = fromBaseOrValue(baseColour, config.secondaryText, 1);

  theme.info.main = config.infoMain;
  theme.info.shade = config.infoHover;
  theme.info.contrastText = config.infoText;

  theme.error.main = config.errorMain;
  theme.error.shade = config.errorHover;
  theme.error.contrastText = config.errorText;

  theme.success.main = config.successMain;
  theme.success.shade = config.successHover;
  theme.success.contrastText = config.successText;

  theme.warning.main = config.warningMain;
  theme.warning.shade = config.warningHover;
  theme.warning.contrastText = config.warningText;

  theme.text.primary = fromBaseOrValue(baseColour, config.textPrimary, 1);
  theme.text.secondary = fromBaseOrValue(baseColour, config.textSecondary, 0.75);
  theme.text.disabled = fromBaseOrValue(baseColour, config.textDisabled, 0.5);
  theme.text.link = asValueOrDefault(config.textLink, theme.text.primary);

  theme.border.weak = fromBaseOrValue(borderColour, config.borderWeak, 0.12);
  theme.border.medium = fromBaseOrValue(borderColour, config.borderMedium, 0.3);
  theme.border.strong = fromBaseOrValue(borderColour, config.borderStrong, 0.4);

  theme.action.hover = fromBaseOrValue(baseColour, config.actionHover, 0.12);
  theme.action.selected = fromBaseOrValue(baseColour, config.actionSelected, 0.08);
  theme.action.focus = fromBaseOrValue(baseColour, config.actionFocus, 0.12);
  theme.action.disabledBackground = fromBaseOrValue(baseColour, config.actionDisabledBackground, 0.04);

  // These values are inherited from white | dark theme config
  // but don't really present any change so they will not be allowed
  // to be modified
  theme.contrastThreshold = 3;
  theme.hoverFactor = 0.03;
  theme.tonalOffset = 0.2;
  theme.action.hoverOpacity = 0.08;
  theme.action.disabledOpacity = 0.38;
}
