import { merge } from 'lodash';

import {
  AuthSettings,
  BootData,
  BuildInfo,
  createTheme,
  DataSourceInstanceSettings,
  FeatureToggles,
  GrafanaConfig,
  GrafanaTheme,
  GrafanaTheme2,
  LicenseInfo,
  MapLayerOptions,
  OAuthSettings,
  PanelPluginMeta,
  PreloadPlugin,
  systemDateFormats,
  SystemDateFormatSettings,
  NewThemeOptions,
  WideSkyCustomTheme,
} from '@grafana/data';


export interface AzureSettings {
  cloud?: string;
  managedIdentityEnabled: boolean;
}

export class GrafanaBootConfig implements GrafanaConfig {
  isPublicDashboardView: boolean;
  datasources: { [str: string]: DataSourceInstanceSettings } = {};
  panels: { [key: string]: PanelPluginMeta } = {};
  auth: AuthSettings = {};
  minRefreshInterval = '';
  appUrl = '';
  appSubUrl = '';
  windowTitlePrefix = '';
  buildInfo: BuildInfo;
  newPanelTitle = '';
  bootData: BootData;
  externalUserMngLinkUrl = '';
  externalUserMngLinkName = '';
  externalUserMngInfo = '';
  allowOrgCreate = false;
  feedbackLinksEnabled = true;
  disableLoginForm = false;
  defaultDatasource = ''; // UID
  alertingEnabled = false;
  alertingErrorOrTimeout = '';
  alertingNoDataOrNullValues = '';
  alertingMinInterval = 1;
  angularSupportEnabled = false;
  authProxyEnabled = false;
  exploreEnabled = false;
  queryHistoryEnabled = false;
  helpEnabled = false;
  profileEnabled = false;
  ldapEnabled = false;
  jwtHeaderName = '';
  jwtUrlLogin = false;
  sigV4AuthEnabled = false;
  azureAuthEnabled = false;
  samlEnabled = false;
  samlName = '';
  autoAssignOrg = true;
  verifyEmailEnabled = false;
  oauth: OAuthSettings = {};
  rbacEnabled = true;
  disableUserSignUp = false;
  loginHint = '';
  passwordHint = '';
  loginError = undefined;
  viewersCanEdit = false;
  editorsCanAdmin = false;
  disableSanitizeHtml = false;
  liveEnabled = true;
  /** @deprecated Use `theme2` instead. */
  theme: GrafanaTheme;
  theme2: GrafanaTheme2;
  pluginsToPreload: PreloadPlugin[] = [];
  featureToggles: FeatureToggles = {};
  licenseInfo: LicenseInfo = {} as LicenseInfo;
  rendererAvailable = false;
  dashboardPreviews: {
    systemRequirements: {
      met: boolean;
      requiredImageRendererPluginVersion: string;
    };
    thumbnailsExist: boolean;
  } = { systemRequirements: { met: false, requiredImageRendererPluginVersion: '' }, thumbnailsExist: false };
  rendererVersion = '';
  secretsManagerPluginEnabled = false;
  http2Enabled = false;
  dateFormats?: SystemDateFormatSettings;
  sentry = {
    enabled: false,
    dsn: '',
    customEndpoint: '',
    sampleRate: 1,
  };
  grafanaJavascriptAgent = {
    enabled: false,
    customEndpoint: '',
    apiKey: '',
    errorInstrumentalizationEnabled: true,
    consoleInstrumentalizationEnabled: false,
    webVitalsInstrumentalizationEnabled: false,
  };
  pluginCatalogURL = 'https://grafana.com/grafana/plugins/';
  pluginAdminEnabled = true;
  pluginAdminExternalManageEnabled = false;
  pluginCatalogHiddenPlugins: string[] = [];
  expressionsEnabled = false;
  customTheme?: undefined;
  awsAllowedAuthProviders: string[] = [];
  awsAssumeRoleEnabled = false;
  azure: AzureSettings = {
    managedIdentityEnabled: false,
  };
  caching = {
    enabled: false,
  };
  geomapDefaultBaseLayerConfig?: MapLayerOptions;
  geomapDisableCustomBaseLayer?: boolean;
  unifiedAlertingEnabled = false;
  unifiedAlerting = { minInterval: '' };
  applicationInsightsConnectionString?: string;
  applicationInsightsEndpointUrl?: string;
  recordedQueries = {
    enabled: true,
  };
  featureHighlights = {
    enabled: false,
  };
  reporting = {
    enabled: true,
  };
  googleAnalyticsId: undefined;
  googleAnalytics4Id: undefined;
  googleAnalytics4SendManualPageViews = false;
  rudderstackWriteKey: undefined;
  rudderstackDataPlaneUrl: undefined;
  rudderstackSdkUrl: undefined;
  rudderstackConfigUrl: undefined;

  tokenExpirationDayLimit: undefined;
  // WideSky Grafana white labeling
  footerElement1Hide = false;
  footerElement2Hide = false;
  footerElement3Hide = false;
  footerElement1Icon = undefined;
  footerElement2Icon = undefined;
  footerElement3Icon = undefined;
  footerElement1Text = '';
  footerElement2Text = '';
  footerElement3Text = '';
  footerElement1Link = '';
  footerElement2Link = '';
  footerElement3Link = '';
  footerElement1TextColor = '';
  footerElement2TextColor = '';
  footerElement3TextColor = '';
  footerPipeColor = '';
  browserTabTitle = '';
  appSidebarLogo = '';
  applicationName = '';
  loginBackground = '';
  loginBoxLogo = '';
  loginBoxLogoMaxWidth = '';
  loginBoxLogoMaxWidthMediaBreakpoint = '';
  loginBoxColour = '';
  loginBoxTextColour = '';
  loginBoxTextboxPlaceholderColour = '';
  loginBoxTextboxBorderColour = '';
  loginBoxButtonBgColour = '';
  loginBoxButtonHoverBgColour = '';
  loginBoxButtonTextColour = '';
  loginBoxButtonHoverTextColour = '';
  loginBoxLinkButtonBgColour = '';
  loginBoxLinkButtonHoverBgColour = '';
  loginBoxLinkButtonTextColour = '';
  loginBoxLinkButtonHoverTextColour = '';
  wsLanguageAssignments = {
    enabled: false,
    assignments: {}
  };

  // WideSky Custom Theme
  wsTheme: WideSkyCustomTheme;

  constructor(options: GrafanaBootConfig) {
    this.bootData = options.bootData;
    this.isPublicDashboardView = options.bootData.settings.isPublicDashboardView;

    const defaults = {
      datasources: {},
      windowTitlePrefix: 'Widesky - ',
      panels: {},
      newPanelTitle: 'Panel Title',
      playlist_timespan: '1m',
      unsaved_changes_warning: true,
      appUrl: '',
      appSubUrl: '',
      buildInfo: {
        version: '1.0',
        commit: '1',
        env: 'production',
      },
      viewersCanEdit: false,
      editorsCanAdmin: false,
      disableSanitizeHtml: false,
      hideVersion: true,
    };

    merge(this, defaults, options);

    this.buildInfo = options.buildInfo || defaults.buildInfo;

    if (this.dateFormats) {
      systemDateFormats.update(this.dateFormats);
    }

    overrideFeatureTogglesFromUrl(this);
    // Creating theme after apply feature toggle overrides as the code below is checking a feature toggle right now
    this.theme2 = createTheme(getThemeCustomizations(this), this.bootData.settings.wsTheme as WideSkyCustomTheme);

    this.theme = this.theme2.v1;
    // Special feature toggle that impact theme/component looks
    this.theme2.flags.topnav = this.featureToggles.topnav;

    this.wsTheme = options.wsTheme;
  }
}

function getThemeCustomizations(config: GrafanaBootConfig) {
  const mode = config.bootData.user.themeName ?? 'dark';
  const themeOptions: NewThemeOptions = {
    colors: { mode },
  };

  return themeOptions;
}

function overrideFeatureTogglesFromUrl(config: GrafanaBootConfig) {
  if (window.location.href.indexOf('__feature') === -1) {
    return;
  }

  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (key.startsWith('__feature.')) {
      const featureName = key.substring(10);
      const toggleState = value === 'true';
      if (toggleState !== config.featureToggles[key]) {
        config.featureToggles[featureName] = toggleState;
        console.log(`Setting feature toggle ${featureName} = ${toggleState}`);
      }
    }
  });
}

const bootData = (window as any).grafanaBootData || {
  settings: {},
  user: {},
  navTree: [],
};

const options = bootData.settings;
options.bootData = bootData;

/**
 * Use this to access the {@link GrafanaBootConfig} for the current running Grafana instance.
 *
 * @public
 */
export const config = new GrafanaBootConfig(options);
